/* Built In Imports */
/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */

/* Services */

/* Styles */
/* Services */

/**
 * @param {Object} sectionContent
 * @param {String} region
 * @param {String} lang
 * @returns {ReactElement} ResearchIntroCard
 */
const ResearchVidCard = ({ children, ...props }) => {
  return <Box {...props}>{children}</Box>;
};

export default ResearchVidCard;
